$(document).ready(function() {
	$('[data-toggle="scroll"]').click(function(event) {
		event.preventDefault();

		var target = $(this).attr('href'),
			st = $(target).offset().top;

		$('html, body').animate({
			scrollTop: st},
			500, function() {
		});
	});	
});